import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container container">
                <h1 className="footer__title">Phyo</h1>

                <ul className="footer__list">
                    <li>
                        <a href="#about" className='footer__link'>About</a>
                    </li>

                    <li>
                        <a href="#portfolio" className='footer__link'>Projects</a>
                    </li>

                    <li>
                        <a href="#testimonial" className='footer__link'>Testimonials</a>
                    </li>
                </ul>

                <div className="footer__social">
                    <a href="https://www.linkedin.com/in/phyo-dev/" className="footer__social-link" rel="noreferrer" target="_blank">
                        <i class="bx bxl-linkedin"></i>
                    </a>

                    <a href="https://facebook.com/phyodev.kophyo" className="footer__social-link" rel="noreferrer" target="_blank">
                        <i class="bx bxl-facebook"></i>
                    </a>

                    <a href="https://github.com/phyodev" className="footer__social-link" rel="noreferrer" target="_blank">
                        <i class="bx bxl-github "></i>
                    </a>

                    <a href="https://youtube.com/@phyodev" className="footer__social-link" rel="noreferrer" target="_blank">
                        <i class="bx bxl-youtube "></i>
                    </a>
                </div>

                <span className="footer__copy">
                    &#169; PhyoPyaeSone. All rights reserved.
                </span>
            </div>
        </footer>
    )
}

export default Footer