import React from 'react';

const Social = () => {
  return (
    <div className="home__social">
      <a href="https://www.linkedin.com/in/phyo-dev/" className="home__social-icon" rel="noreferrer" target="_blank">
        <i class="uil uil-linkedin-alt"></i>
      </a>

      <a href="https://facebook.com/phyodev.kophyo" className="home__social-icon" rel="noreferrer" target="_blank">
        <i class="uil uil-facebook-f"></i>
      </a>

      <a href="https://github.com/phyodev" className="home__social-icon" rel="noreferrer" target="_blank">
        <i class="uil uil-github-alt "></i>
      </a>

      <a href="https://www.youtube.com/@phyodev" className="home__social-icon" rel="noreferrer" target="_blank">
      <i class="uil uil-youtube"></i>
      </a>
    </div>
  )
}

export default Social